const ZoneInMap = [
    {
        id:1,
        region:'Región del Maule',
        comuna:'Talca',
        longitude:-71.65023802887474,
        latitude: -35.42312703367724,
    }
  ]


  export default ZoneInMap;