export const realtorData = {
  email: 'aldanaytorrespropiedades@gmail.com',
  phone:'+56972162551'
};

export const footerData = {
  email: 'aldanaytorrespropiedades@gmail.com',
  phone:'972162551',
  wsp:'972162551',
}
